import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Enable Orientation "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('lottie-player',{staticStyle:{"width":"auto","height":"250px"},attrs:{"src":"https://assets3.lottiefiles.com/packages/lf20_qdajd6nh.json","background":"transparent","speed":"1","loop":"","autoplay":""}})],1),_c(VCardText,{staticClass:"mt-5"},[_c('p',[_vm._v("We'll need to access device orientation to give you better experience.")]),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"text-capitalize",attrs:{"block":"","large":"","color":"info"},on:{"click":function($event){return _vm.askPermission()}}},[_vm._v("Allow")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }