<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card class="mx-auto">
      <v-card-title>
        Enable Orientation
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="d-flex justify-center">
        <lottie-player
          src="https://assets3.lottiefiles.com/packages/lf20_qdajd6nh.json"
          background="transparent"
          speed="1"
          style="width: auto; height: 250px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <v-card-text class="mt-5">
        <p>We'll need to access device orientation to give you better experience.</p>
        <div class="text-center">
          <v-btn block class="text-capitalize" large color="info" @click="askPermission()"
            >Allow</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Orientation from '../../services/orientation';
import '@lottiefiles/lottie-player';
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.orientationPermission;
      },
      set(value) {
        this.$store.commit('setDialog', { key: 'orientationPermission', value });
      }
    }
  },
  methods: {
    askPermission() {
      const orientation = new Orientation();
      orientation.getPermission().finally(() => {
        this.dialog = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
</style>
